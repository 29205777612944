.modal {
  padding: 32px !important;
  width: 480px !important;
  background: linear-gradient(180deg, rgba(3, 152, 85, 0.05) 0%, rgba(3, 152, 85, 0) 22.44%), #F9FAFB !important;
}

.copyIcon {
  background-image: url(./assets/copy.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.copyIcon:hover {
  background-image: url(./assets/copy-hover.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.copyIcon.copied {
  background-image: url(./assets/copied.svg);
}