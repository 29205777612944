.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 362px;
    max-height: 552px;

    border: 0.5px solid #EAECF0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    background: #fff;
}
