.spin-animation path {
    animation: custom 2s linear infinite;
}

@keyframes custom {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.1;
    }

    50% {
        opacity: 0.2;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.spin-animation path:nth-child(1) {
    animation-delay: 0s;
}

.spin-animation path:nth-child(2) {
    animation-delay: 0.5s;
}

.spin-animation path:nth-child(3) {
    animation-delay: 1s;
}

.spin-animation path:nth-child(4) {
    animation-delay: 2s;
}
