.header-DEVELOPMENT {
  background: linear-gradient(180deg, rgba(253, 176, 34, 0.08) 0%, rgba(253, 176, 34, 0) 100%);
  border-top: 4px solid #FDB022;
}

.header-TESTING {
  background: linear-gradient(180deg, rgba(6, 174, 212, 0.08) 0%, rgba(6, 174, 212, 0) 100%);
  border-top: 4px solid #06AED4;
}

.alpha {
  width: 12px;
  height: 12px;
  background: url(./assets/alpha.svg) center center no-repeat;
}